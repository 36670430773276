import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Step,
  Stepper,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { fieldUnit } from "../IssueList/fields/fieldUnit";
import { fieldDescription } from "../IssueList/fields/fieldDescription";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  selectIssueActionCodesByIssueIdAndProfile,
  selectIssueById,
  selectSelectedIssue,
  selectSelectedIssueProperty,
} from "../../selectors/issueSelectors";
import { Edit } from "@mui/icons-material";
import { fieldService } from "../IssueList/fields/fieldService";
import { fieldProgress } from "../IssueList/fields/fieldProgress";
import ChipIssueStatus from "../Chip/ChipIssueStatus";
import {
  getIssueStatusByCode,
  ISSUE_STATUS_CODE__done,
  ISSUE_STATUS_CODE__inProgress,
  ISSUE_STATUS_CODE__new,
} from "../../../../libraries/enums/issueStatuses";
import { fieldComments } from "../IssueList/fields/fieldComments";
import issueSlice from "../../slices/issueSlice";
import { grey } from "@mui/material/colors";
import IssueDetailFieldServiceCompany from "./IssueDetailField/IssueDetailFieldServiceCompany";
import IssueDetailFieldServiceCompanySentToBtn from "./IssueDetailField/IssueDetailFieldServiceCompanySentToBtn";
import IssueDetailFieldCommentNew from "./IssueDetailField/IssueDetailFieldCommentNew";
import IssueDetailFieldServiceCompanyServiceList from "./IssueDetailField/IssueDetailFieldServiceCompanyServiceList";
import { getIssueActionByIssueActionCode } from "../../../../libraries/enums/issueActions";
import { useTheme } from "@mui/material/styles";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import {
  PERMISSION_RIGHT_CODE__assignServiceCompanyToIssue,
  PERMISSION_RIGHT_CODE__assignServiceTechnicianToIssue,
  PERMISSION_RIGHT_CODE__updateIssue,
} from "../../../../libraries/enums/permissionRights";
import ChipUserType from "../Chip/ChipUserType";
import {
  USER_TYPE_CODE__serviceCompany,
  USER_TYPE_CODE__serviceTechnician,
} from "../../../../libraries/enums/userTypes";
import IssueDetailFieldServiceTechnician from "./IssueDetailField/IssueDetailFieldServiceTechnician";
import {
  selectServiceCompanyById,
  selectServiceTechnicianById,
} from "../../../enum/selectors/enumSelectors";
import { formatDate } from "../../../common/utils/dateTime.utils";
import { IssueType } from "../../../../types";
import { EntityId } from "@reduxjs/toolkit";
import { fieldAssets } from "../IssueList/fields/fieldAssets";

const IssueDetailField = (props: any) => {
  const { field, fieldProps, children, onlyChildren = false } = props;

  return (
    <Card sx={{ mb: 2 }} variant="outlined">
      <CardHeader
        title={field.label}
        titleTypographyProps={{
          sx: {
            fontSize: 15,
            fontWeight: 500,
          },
        }}
        sx={{ bgcolor: grey[100] }}
      />
      <Divider />
      <CardContent
        sx={{
          p: 0,
          "&:last-child": {
            p: 0,
          },
        }}
      >
        {!onlyChildren && React.createElement(field.component, fieldProps)}
        {!!children && children}
      </CardContent>
    </Card>
  );
};

const IssueDetailStepper = (props: any) => {
  const issue = useAppSelector((state) =>
    selectIssueById(state, props.issueId)
  );

  if (!issue) {
    return <></>;
  }

  return (
    <>
      <Stepper
        sx={{
          my: 1,
          "& .MuiStepConnector-line": {
            borderColor: "rgba(0, 0, 0, 0.12)",
          },
        }}
      >
        <Step>
          <ChipIssueStatus
            issueStatusCode={ISSUE_STATUS_CODE__new}
            label={getIssueStatusByCode(ISSUE_STATUS_CODE__new).label}
            active={[ISSUE_STATUS_CODE__new].includes(issue.statusId)}
          />
        </Step>
        <Step>
          <ChipIssueStatus
            issueStatusCode={ISSUE_STATUS_CODE__inProgress}
            label={getIssueStatusByCode(ISSUE_STATUS_CODE__inProgress).label}
            active={[ISSUE_STATUS_CODE__inProgress].includes(issue.statusId)}
          />
        </Step>
        <Step>
          <ChipIssueStatus
            issueStatusCode={ISSUE_STATUS_CODE__done}
            label={getIssueStatusByCode(ISSUE_STATUS_CODE__done).label}
            active={[ISSUE_STATUS_CODE__done].includes(issue.statusId)}
          />
        </Step>
      </Stepper>
    </>
  );
};

const IssueDetailActions = (props: any) => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useAppDispatch();

  const actionCodes = useAppSelector((state) =>
    selectIssueActionCodesByIssueIdAndProfile(state, { issueId: props.issueId })
  );

  if (actionCodes.length === 0) {
    return <></>;
  }

  return (
    <ButtonGroup
      variant={"outlined"}
      sx={{ mb: 1 }}
      orientation={mdUp ? "horizontal" : "vertical"}
      fullWidth={true}
    >
      {actionCodes.map((actionCode) => {
        // @ts-ignore
        const action = getIssueActionByIssueActionCode(actionCode);
        return (
          <Button
            key={actionCode}
            startIcon={React.createElement(action.icon)}
            onClick={() => {
              action.onClickFn({ dispatch });
            }}
          >
            {action.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

const IssueDetailDoneInfo = (props: any) => {
  const issue = useAppSelector((state) =>
    selectIssueById(state, props.issueId)
  );

  if (!issue) {
    return <></>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Alert severity={issue.markedAsDoneByUnitAt ? "success" : "info"}>
          <AlertTitle>Vyřízeno za středisko:</AlertTitle>
          {issue.markedAsDoneByUnitAt
            ? formatDate(issue.markedAsDoneByUnitAt)
            : "Nevyřízeno"}
        </Alert>
      </Grid>

      <Grid item xs={12} md={6}>
        <Alert
          severity={issue.markedAsDoneByServiceCompanyAt ? "success" : "info"}
        >
          <AlertTitle>Vyřízeno za servisní firmu:</AlertTitle>
          {issue.markedAsDoneByServiceCompanyAt
            ? formatDate(issue.markedAsDoneByServiceCompanyAt)
            : "Nevyřízeno"}
        </Alert>
      </Grid>
    </Grid>
  );
};

const IssueDetailFieldServiceInfo = (props: { issue: IssueType }) => {
  const issue = useAppSelector((state) =>
    selectIssueById(state, props.issue.id)
  );

  const serviceCompany = useAppSelector((state) =>
    selectServiceCompanyById(
      state,
      !!issue ? (issue.serviceCompanyId as unknown as EntityId) : ""
    )
  );

  const serviceTechnician = useAppSelector((state) =>
    selectServiceTechnicianById(
      state,
      !!issue ? (issue.serviceTechnicianId as unknown as EntityId) : ""
    )
  );

  if (!issue || !serviceCompany) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ m: 2, display: "flex" }}>
        {!!serviceCompany && (
          <Box sx={{ mr: 0.5 }}>
            <ChipUserType
              userTypeCode={USER_TYPE_CODE__serviceCompany}
              label={serviceCompany.title}
            />
          </Box>
        )}
        {!!serviceTechnician && (
          <Box sx={{ mr: 0.5 }}>
            <ChipUserType
              userTypeCode={USER_TYPE_CODE__serviceTechnician}
              label={serviceTechnician.title}
            />
          </Box>
        )}
      </Box>
      <Box sx={{ m: 2 }}>
        {issue.sentToServiceCompanyAt && (
          <Alert severity="info" sx={{ my: 2 }}>
            Odesláno servisní firmě {formatDate(issue.sentToServiceCompanyAt)}
          </Alert>
        )}
        {issue.sentToServiceTechnicianAt && (
          <Alert severity="info" sx={{ my: 2 }}>
            Odesláno servisnímu technikovi{" "}
            {formatDate(issue.sentToServiceTechnicianAt)}
          </Alert>
        )}
      </Box>
    </>
  );
};

const IssueDetailModeRead = () => {
  const dispatch = useAppDispatch();
  const issue = useAppSelector(selectSelectedIssue);
  const issueId = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "id" })
  );

  const rightUpdateIssue = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__updateIssue,
    })
  );
  const rightAssignServiceCompany = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__assignServiceCompanyToIssue,
    })
  );
  const rightAssignServiceTechnician = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__assignServiceTechnicianToIssue,
    })
  );

  if (!issue) {
    return <></>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <IssueDetailStepper issueId={issueId} />
      </Grid>

      <Grid item xs={12}>
        <IssueDetailActions issueId={issueId} />
      </Grid>

      <Grid item xs={12}>
        <IssueDetailDoneInfo issueId={issueId} />
      </Grid>

      <Grid item xs={12} md={6}>
        <IssueDetailField
          field={fieldDescription}
          fieldProps={{ issueId, isDetail: true }}
        >
          {rightUpdateIssue && (
            <Box textAlign={"right"}>
              <Divider />
              <Button
                sx={{ mr: 2, my: 2 }}
                variant={"outlined"}
                color={"secondary"}
                startIcon={<Edit />}
                size={"small"}
                onClick={() => {
                  dispatch(issueSlice.actions.selectedIssueModeSet("update"));
                }}
              >
                Upravit požadavek
              </Button>
            </Box>
          )}
        </IssueDetailField>
        <IssueDetailField field={fieldUnit} fieldProps={{ issueId }} />
        <IssueDetailField
          field={fieldProgress}
          fieldProps={{ issueId, showCount: 999 }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {!!issue.assetIds && issue.assetIds.length > 0 && (
          <IssueDetailField field={fieldAssets} fieldProps={{ issueId }} />
        )}

        <IssueDetailField
          field={fieldService}
          fieldProps={{ issueId }}
          onlyChildren={true}
        >
          <IssueDetailFieldServiceInfo issue={issue} />

          {!issue.sentToServiceCompanyAt && rightAssignServiceCompany && (
            <Box sx={{ m: 2 }}>
              <IssueDetailFieldServiceCompany />
              <IssueDetailFieldServiceCompanySentToBtn />
            </Box>
          )}

          {!!issue.sentToServiceCompanyAt && rightAssignServiceTechnician && (
            <Box sx={{ m: 2 }}>
              <IssueDetailFieldServiceTechnician />
            </Box>
          )}

          <IssueDetailFieldServiceCompanyServiceList />
        </IssueDetailField>
        <IssueDetailField
          field={fieldComments}
          fieldProps={{ issueId, showCount: 999, isDetail: true }}
        >
          <Box sx={{ p: 2 }}>
            <IssueDetailFieldCommentNew />
          </Box>
        </IssueDetailField>
      </Grid>
    </Grid>
  );
};

export default IssueDetailModeRead;
