//@ts-nocheck

import { Box, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { selectSelectedIssueProperty } from "../../../selectors/issueSelectors";
import { fieldAsset } from "../../../../asset/components/fields/fieldAsset";
import SearchIcon from "@mui/icons-material/Search";
import assetSlice from "../../../../asset/slices/asset.slice";
import { selectAssetById } from "../../../../asset/selectors/asset.selector";
import { fieldIdentifiers } from "../../../../asset/components/fields/fieldIdentifiers";
import issueSlice from "../../../slices/issueSlice";

const FieldAsset = (props) => {
  const { assetId } = props;

  const dispatch = useAppDispatch();
  const asset = useAppSelector((state) => selectAssetById(state, assetId));

  useEffect(() => {
    dispatch(assetSlice.actions.listFetchByAssetId({ assetId }));
  }, [assetId]);

  if (!asset) {
    return <></>;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Box>
          {React.createElement(fieldAsset.component, {
            assetId: asset.id,
          })}
        </Box>
        <Box>
          {React.createElement(fieldIdentifiers.component, {
            assetId: asset.id,
          })}
        </Box>
      </Box>
      <Box sx={{ p: 2 }}>
        <IconButton
          color={"primary"}
          onClick={() => {
            dispatch(assetSlice.actions.selectedAssetSet(asset));
            dispatch(assetSlice.actions.selectedAssetModeSet("read"));
            dispatch(issueSlice.actions.selectedIssueSet(null));
            dispatch(issueSlice.actions.selectedIssueModeSet(null));
            dispatch(issueSlice.actions.selectedIssueClosingDialogSet(false));
          }}
        >
          <SearchIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

const FieldAssets = (props) => {
  const assetIds = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "assetIds" })
  );

  return (
    <Box sx={{ p: 0 }}>
      {!!assetIds &&
        assetIds.length > 0 &&
        assetIds.map((assetId) => {
          return <FieldAsset key={assetId} assetId={assetId} />;
        })}
    </Box>
  );
};

export const fieldAssets = {
  code: "fieldAssets",
  label: "Zařízení",
  valueGetterFn: (issueId) => {
    return "";
  },
  component: FieldAssets,
};
