import { useAppSelector } from "../../../../app/store";
import {
  selectAssetById,
  selectAssetLastAssetUnitRelationPresence,
  selectAssetRelations,
} from "../../selectors/asset.selector";
import { Box } from "@mui/system";
import React from "react";
import { AssetType } from "../../../../types/asset.types";
import { Avatar, AvatarGroup, Chip, Typography } from "@mui/material";
// @ts-ignore
import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm";
import { File } from "../../../../types/file.types";
import { shallowEqual } from "react-redux";
import ChipLastAssetMovement from "../../../common/components/Chip/ChipLastAssetMovement";
import { getLastAssetMovement } from "../../utils/assetMovement.utils";
import { green, orange, red } from "@mui/material/colors";
import { getAssetMovementReasonLabel } from "../../../../libraries/enums/assetMovementReasons";
import { formatDate } from "../../../common/utils/dateTime.utils";

export const FieldAssetContent = ({
  asset,
  isDetail = false,
}: {
  asset: AssetType;
  isDetail?: boolean | undefined;
}) => {
  const unitRelations = useAppSelector(
    (state) =>
      selectAssetRelations(state, {
        assetId: asset.id,
        relationName: "unitRelations",
      }),
    {
      equalityFn: shallowEqual,
    }
  );
  const lastAssetUnitRelationPresence = useAppSelector((state) =>
    selectAssetLastAssetUnitRelationPresence(state, {
      assetId: asset.id,
      relationName: "unitRelations",
    })
  );

  const files = asset.files.filter((file: File) => file.type === "asset_photo");

  const lastAssetMovement = getLastAssetMovement(asset);

  const toggleFancybox = (e: any) => {
    e.stopPropagation();
    const fancybox = Fancybox.show(
      files.map((file: File) => {
        return {
          type: "image",
          src:
            process.env.REACT_APP_ENDPOINT_FILES +
            "/" +
            file.path +
            "/originals/" +
            file.filename,
        };
      }),
      {
        infinite: false,
      }
    );
  };

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <AvatarGroup
          spacing="small"
          max={2}
          variant="circular"
          total={files.length}
          sx={{ mr: files.length === 0 ? 0 : 1, cursor: "pointer" }}
          onClick={toggleFancybox}
        >
          {files.map((file: File) => {
            return (
              <Avatar
                key={file.id}
                src={
                  process.env.REACT_APP_ENDPOINT_FILES +
                  "/" +
                  file.path +
                  "/thumbnails/" +
                  file.filename
                }
              />
            );
          })}
        </AvatarGroup>

        <Box>
          <Typography
            sx={{
              overflow: "hidden",
            }}
            component="div"
            variant="body2"
          >
            {isDetail ? (
              <>{asset.title}</>
            ) : (
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {asset.title}
              </Box>
            )}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              mt: .5,
              mb: !!asset.inventoryNumber ? 1 : 0,
            }}
          >
            {!!asset.inventoryNumber && (
              <Chip
                label={asset.inventoryNumber}
                size="small"
                sx={{
                  mr: 1,
                }}
              />
            )}
            {!lastAssetMovement && unitRelations.length === 0 && (
              <Box sx={{ mr: 1, my: 0.5 }}>
                <ChipLastAssetMovement
                  label={"Není na žádném středisku"}
                  size={"small"}
                  background={red[600]}
                />
              </Box>
            )}
            {!!lastAssetMovement && lastAssetMovement.direction.id === "OUT" && (
              <Box sx={{ mr: 1, my: 0.5 }}>
                <ChipLastAssetMovement
                  label={
                    "Mimo středisko / " +
                    getAssetMovementReasonLabel(lastAssetMovement.reason.id)
                  }
                  size={"small"}
                  background={orange[600]}
                />
              </Box>
            )}
            {!!lastAssetUnitRelationPresence &&
              lastAssetUnitRelationPresence.resultCode === "CHECKED_AND_EXISTS" && (
                <Box sx={{ mr: 1, my: 0.5 }}>
                  <ChipLastAssetMovement
                    label={
                      formatDate(
                        lastAssetUnitRelationPresence.createdAt,
                        "d. L. yyyy"
                      ) + " potvrzeno, že je na středisku"
                    }
                    size={"small"}
                    background={green[600]}
                  />
                </Box>
              )}
            {!!lastAssetUnitRelationPresence &&
              lastAssetUnitRelationPresence.resultCode ===
              "CHECKED_AND_DOES_NOT_EXIST" && (
                <Box sx={{ mr: 1, my: 0.5 }}>
                  <ChipLastAssetMovement
                    label={
                      formatDate(
                        lastAssetUnitRelationPresence.createdAt,
                        "d. L. yyyy"
                      ) + " označeno, že není na středisku"
                    }
                    size={"small"}
                    background={red[600]}
                  />
                </Box>
              )}
            {/*{!!asset.statusId && (*/}
            {/*  <Box sx={{ mr: 1, my: 0.5 }}>*/}
            {/*    <ChipAssetStatus*/}
            {/*      assetStatusCode={asset.statusId}*/}
            {/*      label={getAssetStatusByCode(asset.statusId)?.label || ""}*/}
            {/*      active={true}*/}
            {/*      size={"small"}*/}
            {/*    />*/}
            {/*  </Box>*/}
            {/*)}*/}
            {/*<Box sx={{ my: 0.5 }}>*/}
            {/*<ChipIssueStatus*/}
            {/*  issueStatusCode={issue.statusId}*/}
            {/*  label={getIssueStatusByCode(issue.statusId).label}*/}
            {/*  active={true}*/}
            {/*  size={"small"}*/}
            {/*/>*/}
            {/*</Box>*/}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const FieldAsset = ({
  assetId,
  isDetail = false,
}: {
  assetId: string;
  isDetail?: boolean | undefined;
}) => {
  const asset = useAppSelector((state) => selectAssetById(state, assetId));

  if (!asset) {
    return <></>;
  }

  return (
    <Box sx={{ p: 2, width: "100%" }}>
      <FieldAssetContent asset={asset} isDetail={isDetail} />
    </Box>
  );
};

export const fieldAsset = {
  code: "title",
  label: "Zařízení",
  component: FieldAsset,
};
