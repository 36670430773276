import {
  Box,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from "@mui/material";
import React from "react";
import { grey } from "@mui/material/colors";
import { useAppSelector } from "../../../../../app/store";
import { selectSelectedAsset } from "../../../selectors/asset.selector";
import { fieldAsset } from "../../fields/fieldAsset";
import { fieldUnit } from "../../fields/fieldUnit";
import { fieldIdentifiers } from "../../fields/fieldIdentifiers";
import { fieldManufacturer } from "../../fields/fieldManufacturer";
import { fieldSupplier } from "../../fields/fieldSupplier";
import { fieldAssetMovements } from "../../fields/fieldAssetMovements";
import AssetMovementCreateButton from "../../AssetMovement/AssetMovementCreateButton";
import AssetUpdateButton from "../../AssetUpdateButton/AssetUpdateButton";
import { fieldDeliveryDate } from "../../fields/fieldDeliveryDate";
import { ActionAssetUnitRelationPresence } from "../../../../assetFinder/components/AssetFinderResult/AssetFinderResultAssetActions";

const AssetDetailField = (props: any) => {
  const { field, fieldProps, children, onlyChildren = false } = props;

  return (
    <Card sx={{ mb: 2 }} variant="outlined">
      <CardHeader
        title={field.label}
        titleTypographyProps={{
          sx: {
            fontSize: 15,
            fontWeight: 500,
          },
        }}
        sx={{ bgcolor: grey[100] }}
      />
      <Divider />
      <CardContent
        sx={{
          p: 0,
          "&:last-child": {
            p: 0,
          },
        }}
      >
        {!onlyChildren && React.createElement(field.component, fieldProps)}
        {!!children && children}
      </CardContent>
    </Card>
  );
};

const AssetDetailModeReadTabInfo = () => {
  const asset = useAppSelector(selectSelectedAsset);

  if (!asset) {
    return <></>;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <AssetDetailField
            field={fieldAsset}
            fieldProps={{ assetId: asset.id, isDetail: true }}
          >
            <AssetUpdateButton />
          </AssetDetailField>
          <AssetDetailField
            field={fieldUnit}
            fieldProps={{ assetId: asset.id, isDetail: true }}
          />
          <Box>
            <ButtonGroup
              fullWidth={true}
              variant={"outlined"}
              color={"secondary"}
              orientation={"vertical"}
              sx={{ mb: 1 }}
            >
              <ActionAssetUnitRelationPresence
                asset={asset}
                resultCode={"CHECKED_AND_EXISTS"}
              />
              <ActionAssetUnitRelationPresence
                asset={asset}
                resultCode={"CHECKED_AND_DOES_NOT_EXIST"}
              />
            </ButtonGroup>
          </Box>
          {/*<AssetDetailField*/}
          {/*  field={fieldUnitRelations}*/}
          {/*  fieldProps={{ assetId: asset.id, isDetail: true }}*/}
          {/*/>*/}
        </Grid>
        <Grid item xs={12} md={6}>
          <AssetDetailField
            field={fieldIdentifiers}
            fieldProps={{ assetId: asset.id, isDetail: true }}
          />
          <AssetDetailField
            field={fieldManufacturer}
            fieldProps={{ assetId: asset.id, isDetail: true }}
          />
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <AssetDetailField
                field={fieldSupplier}
                fieldProps={{ assetId: asset.id, isDetail: true }}
              />
            </Grid>
            <Grid item xs={5}>
              <AssetDetailField
                field={fieldDeliveryDate}
                fieldProps={{ assetId: asset.id, isDetail: true }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AssetDetailField
            field={fieldAssetMovements}
            fieldProps={{ assetId: asset.id, isDetail: true }}
          >
            <Box sx={{ m: 2, textAlign: "left" }}>
              <AssetMovementCreateButton
                btnProps={{
                  size: "small",
                  variant: "outlined",
                  color: "secondary",
                }}
              />
            </Box>
          </AssetDetailField>
        </Grid>
      </Grid>
    </>
  );
};

export default AssetDetailModeReadTabInfo;
