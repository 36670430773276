import { Box } from "@mui/material";
import IssueDetailFieldIssueType from "./IssueDetailField/IssueDetailFieldIssueType";
import IssueDetailFieldUnit from "./IssueDetailField/IssueDetailFieldUnit";
import IssueDetailFieldDescription from "./IssueDetailField/IssueDetailFieldDescription";
import IssueDetailFieldAttachments from "./IssueDetailField/IssueDetailFieldAttachments";
import React from "react";
import { useAppSelector } from "../../../../app/store";
import {
  selectProfileHasPermissionRight,
  selectProfileOnlyService,
} from "../../../common/selectors/commonSelectors";
import {
  PERMISSION_RIGHT_CODE__createIssue,
  PERMISSION_RIGHT_CODE__createIssueWithAsset,
} from "../../../../libraries/enums/permissionRights";
import NewsAlert from "../../../common/components/NewsAlert/NewsAlert";
import { selectFlattenUnits } from "../../../enum/selectors/enumSelectors";
import IssueDetailFieldAsset from "./IssueDetailField/IssueDetailFieldAsset";

const IssueDetailModeUpdate = (props: any) => {
  const { handleSubmit } = props;

  const rightCreateIssue = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createIssue,
    })
  );
  const rightCreateIssueWithAsset = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createIssueWithAsset,
    })
  );
  const onlyService = useAppSelector(selectProfileOnlyService);
  const unitOptions = useAppSelector(selectFlattenUnits);

  if (!rightCreateIssue) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ mb: 2 }} hidden={onlyService}>
        <IssueDetailFieldIssueType />
      </Box>
      <Box sx={{ mb: 2 }} hidden={unitOptions.length === 1}>
        <IssueDetailFieldUnit />
      </Box>
      {!!rightCreateIssueWithAsset && (
        <Box sx={{ mb: 0 }}>
          <IssueDetailFieldAsset />
        </Box>
      )}
      <Box sx={{ mb: 2 }}>
        <IssueDetailFieldDescription handleSubmit={handleSubmit} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <NewsAlert
          severity="info"
          title="Věděli jste?"
          content="Že nově můžete přidat více fotografií?"
          localStorageItem="newsAlertMultiplePhotos"
        />
        <IssueDetailFieldAttachments type={"issue_photo"} />
      </Box>
    </>
  );
};

export default IssueDetailModeUpdate;
