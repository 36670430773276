import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ListConfigType, Nullable } from "../../../types";
import { buildInitialFilter } from "../builders/filter.builder";
import { AssetFilterType, AssetType } from "../../../types/asset.types";

type StateType = {
  search: AssetFilterType;
  filter: AssetFilterType;
  list: EntityState<AssetType>;
  listFetching: boolean;
  listFetchingStatus: "init" | "fetching" | "success" | "failure";
  listConfig: ListConfigType;
  selectedAsset: Nullable<AssetType>;
  selectedAssetMode: Nullable<"create" | "update" | "read">;
};

export const assetEntityAdapter = createEntityAdapter<AssetType>();

const initialState: StateType = {
  search: buildInitialFilter(),
  filter: buildInitialFilter(),
  list: assetEntityAdapter.getInitialState(),
  listFetching: false,
  listFetchingStatus: "init",
  listConfig: {
    page: 0,
    pageSize: 25,
    sortBy: null,
    sortByDirection: "ASC",
  },
  selectedAsset: null,
  selectedAssetMode: null,
};

const setPropertyByPath = (obj: any, path: string, value: any) => {
  if (!obj || !path) {
    return;
  }

  // Rozdělí cestu na jednotlivé klíče
  const keys = path.split(".");
  let current = obj;

  // Iteruje přes klíče kromě posledního, postupně prochází nebo vytváří objekty
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];

    // Pokud klíč neexistuje nebo není objektem, vytvoří nový objekt
    if (
      current[key] === undefined ||
      typeof current[key] !== "object" ||
      !current[key]
    ) {
      current[key] = {};
    }

    current = current[key];
  }

  // Nastaví hodnotu na poslední klíč v cestě
  current[keys[keys.length - 1]] = value;
};

const assetSlice = createSlice({
  name: "asset",
  initialState: initialState,
  reducers: {
    listFetchByAssetId: (
      state: StateType,
      action: PayloadAction<{ assetId: string }>
    ) => {},
    listFetch: () => {},
    listFetching: (state: StateType, action: PayloadAction<boolean>) => {
      state.listFetching = action.payload;
    },
    listFetchingStatus: (
      state: StateType,
      action: PayloadAction<"init" | "fetching" | "success" | "failure">
    ) => {
      state.listFetchingStatus = action.payload;
    },
    listRemoveAll: (state: StateType) => {
      assetEntityAdapter.removeAll(state.list);
    },
    listSet: (state: StateType, action: PayloadAction<AssetType[]>) => {
      assetEntityAdapter.setAll(state.list, action.payload);
    },
    listUpsertMany: (state: StateType, action: PayloadAction<AssetType[]>) => {
      assetEntityAdapter.upsertMany(state.list, action.payload);
    },
    searchSet: (state: StateType, action: PayloadAction<AssetFilterType>) => {
      state.search = action.payload;
      state.listConfig = { ...state.listConfig, page: 0 };
    },
    searchPropertySet: (
      state: StateType,
      action: PayloadAction<{ property: string; value: any }>
    ) => {
      if (!state.search) {
        state.search = buildInitialFilter();
      }
      // @ts-ignore
      state.search[action.payload.property] = action.payload.value;
    },
    searchSubmit: (state: StateType) => {},
    filterSet: (state: StateType, action: PayloadAction<AssetFilterType>) => {
      state.filter = action.payload;
      state.listConfig = { ...state.listConfig, page: 0 };
    },
    filterPropertySet: (
      state: StateType,
      action: PayloadAction<{ property: string; value: any }>
    ) => {
      if (!state.filter) {
        state.filter = buildInitialFilter();
      }
      // @ts-ignore
      state.filter[action.payload.property] = action.payload.value;
      state.listConfig = { ...state.listConfig, page: 0 };
    },
    listUpsertOne: (state: StateType, action: PayloadAction<AssetType>) => {
      assetEntityAdapter.upsertOne(state.list, action.payload);
    },
    selectedAssetSet: (
      state: StateType,
      action: PayloadAction<Nullable<AssetType>>
    ) => {
      state.selectedAsset = action.payload;
    },
    selectedAssetModeSet: (
      state: StateType,
      action: PayloadAction<Nullable<"create" | "update" | "read">>
    ) => {
      state.selectedAssetMode = action.payload;
    },
    selectedAssetPropertySet: (
      state: StateType,
      action: PayloadAction<any>
    ) => {
      if (!!state.selectedAsset) {
        const property = action.payload.property;
        const value = action.payload.value;
        setPropertyByPath(state.selectedAsset, property, value);
      }
    },
    selectedAssetPropertiesSet: (
      state: StateType,
      action: PayloadAction<any>
    ) => {
      if (!!state.selectedAsset) {
        for (let i = 0; i < action.payload.length; i++) {
          const property = action.payload[i].property;
          const value = action.payload[i].value;
          setPropertyByPath(state.selectedAsset, property, value);
        }
      }
    },
    selectedAssetSetById: (state: StateType, action) => {
      const asset = state.list.entities[action.payload];
      if (!!asset) {
        state.selectedAsset = asset;
        state.selectedAssetMode = "read";
      }
    },
    selectedAssetSubmit: (state: StateType) => {},
    listConfigSet: (state: StateType, action: PayloadAction<any>) => {
      state.listConfig = { ...state.listConfig, ...action.payload };
    },
    assetAssetMovementSubmit: (
      state: StateType,
      action: PayloadAction<any>
    ) => {},
    assetAssetUnitRelationPresenceSubmit: (
      state: StateType,
      action: PayloadAction<any>
    ) => {},
  },
});

export default assetSlice;
