import {
  AnyAction,
  createListenerMiddleware,
  ListenerMiddlewareInstance,
} from "@reduxjs/toolkit";
import { ListenerApiType } from "../../../types/api.types";
import assetSlice from "../slices/asset.slice";
import {
  effectAssetAssetMovementSubmit,
  effectAssetAssetUnitRelationPresenceSubmit,
  effectAssetListFetch,
  effectSelectedAssetSubmit,
} from "./asset.effect";
import {
  selectAssetRelationLast,
  selectAssetSearchProperty,
  selectSelectedAsset,
} from "../selectors/asset.selector";
import {
  AssetManufacturerRelation,
  AssetQrCodeRelation,
  AssetSupplierRelation,
  AssetType,
  AssetUnitRelation,
} from "../../../types/asset.types";
import store from "../../../app/store";
import { assetGetOne } from "../../../api/api";

const assetMiddleware: ListenerMiddlewareInstance = createListenerMiddleware();

//načtení jednoho zařízení
assetMiddleware.startListening({
  type: assetSlice.actions.listFetchByAssetId.type,
  effect: async (action: AnyAction, listenerApi: ListenerApiType) => {
    assetGetOne({ assetId: action.payload.assetId }).then((response: any) => {
      if (response.status === 200) {
        listenerApi.dispatch(
          assetSlice.actions.listUpsertMany(response.data.assets)
        );
      }
    });
  },
});

//inicializační načtení zařízení
assetMiddleware.startListening({
  type: assetSlice.actions.listFetch.type,
  effect: async (action: AnyAction, listenerApi: ListenerApiType) => {
    const queryParams = {
      fulltext: {
        operation: "=",
        value: selectAssetSearchProperty(listenerApi.getState(), {
          property: "fulltext",
        }),
      },
    };
    await effectAssetListFetch(action, listenerApi, {
      resetList: true,
      showFetchingIndicator: true,
      queryParams: queryParams,
    });
  },
});

//při požadavku na vyhledávání zařízení
assetMiddleware.startListening({
  type: assetSlice.actions.searchSubmit.type,
  effect: async (action: AnyAction, listenerApi: ListenerApiType) => {
    const queryParams = {
      fulltext: {
        operation: "=",
        value: selectAssetSearchProperty(listenerApi.getState(), {
          property: "fulltext",
        }),
      },
    };
    await effectAssetListFetch(action, listenerApi, {
      resetList: true,
      showFetchingIndicator: true,
      queryParams: queryParams,
    });
  },
});

//při zadání QR kódu
assetMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    return (
      [assetSlice.actions.searchPropertySet.type].includes(action.type) &&
      action.payload.hasOwnProperty("property") &&
      action.payload.property === "qrCode"
    );
  },
  effect: async (action: AnyAction, listenerApi: ListenerApiType) => {
    const queryParams = {
      qrCode: {
        operation: "=",
        value: selectAssetSearchProperty(listenerApi.getState(), {
          property: "qrCode",
        }),
      },
    };
    await effectAssetListFetch(action, listenerApi, {
      resetList: true,
      showFetchingIndicator: true,
      queryParams: queryParams,
    });
  },
});

//při odeslání zařízení
assetMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    return [assetSlice.actions.selectedAssetSubmit.type].includes(action.type);
  },
  effect: async (action, listenerApi) => {
    await effectSelectedAssetSubmit(action, listenerApi);
  },
});

//při odeslání nového pohybu
assetMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    return [assetSlice.actions.assetAssetMovementSubmit.type].includes(
      action.type
    );
  },
  effect: async (action, listenerApi) => {
    await effectAssetAssetMovementSubmit(action, listenerApi);
  },
});

//při odeslání kontroly přítomnosti zařízení na středisku
assetMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    return [
      assetSlice.actions.assetAssetUnitRelationPresenceSubmit.type,
    ].includes(action.type);
  },
  effect: async (action, listenerApi) => {
    await effectAssetAssetUnitRelationPresenceSubmit(action, listenerApi);
  },
});

const buildInput = (asset: AssetType) => {
  const input: any = {};

  const lastUnitRelation: AssetUnitRelation = selectAssetRelationLast(
    store.getState(),
    {
      assetId: asset.id,
      relationName: "unitRelations",
    }
  );
  if (!!lastUnitRelation) {
    input.unitId = lastUnitRelation.unit.id;
    input.unitPlacement = lastUnitRelation.placement;
  }

  const lastManufacturerRelation: AssetManufacturerRelation =
    selectAssetRelationLast(store.getState(), {
      assetId: asset.id,
      relationName: "manufacturerRelations",
    });
  if (!!lastManufacturerRelation) {
    input.manufacturer = {
      id: lastManufacturerRelation.manufacturer.id,
      title: lastManufacturerRelation.manufacturer.title,
    };
  }

  const lastSupplierRelation: AssetSupplierRelation = selectAssetRelationLast(
    store.getState(),
    {
      assetId: asset.id,
      relationName: "supplierRelations",
    }
  );
  if (!!lastSupplierRelation) {
    input.supplier = {
      id: lastSupplierRelation.supplier.id,
      title: lastSupplierRelation.supplier.title,
    };
  }

  const lastQrCodeRelation: AssetQrCodeRelation = selectAssetRelationLast(
    store.getState(),
    {
      assetId: asset.id,
      relationName: "qrCodeRelations",
    }
  );
  if (!!lastQrCodeRelation) {
    input.qrCode = lastQrCodeRelation.qrCode.content;
  }

  return input;
};

//při setování vybraného zařízení -> je vytvořen input objekt
assetMiddleware.startListening({
  predicate: (action, currentState, originalState) => {
    return (
      [
        assetSlice.actions.selectedAssetSet.type,
        assetSlice.actions.selectedAssetSetById.type,
      ].includes(action.type) && !!action.payload
    );
  },
  effect: (action, listenerApi) => {
    const asset = selectSelectedAsset(store.getState());
    if (!!asset) {
      const input = buildInput(asset);
      listenerApi.dispatch(
        assetSlice.actions.selectedAssetPropertySet({
          property: "input",
          value: input,
        })
      );
    }
  },
});

export default assetMiddleware;
