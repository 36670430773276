import { assetEntityAdapter } from "../slices/asset.slice";
import { RootState } from "../../../app/store";
import { createSelector } from "@reduxjs/toolkit";
import { selectProfile } from "../../auth/selectors/authSelectors";
import {
  AbstractAssetRelation,
  AssetFilterType,
  AssetType,
  AssetUnitRelation,
} from "../../../types/asset.types";
import { getLastAssetMovement } from "../utils/assetMovement.utils";
import { ASSET_MOVEMENT_DIRECTION_CODE__out } from "../../../libraries/enums/assetMovementDirections";

const relationCompareFn = (
  a: AbstractAssetRelation,
  b: AbstractAssetRelation
) => {
  // Pokud date je undefined, předpokládáme, že je nejmladší
  const dateA = new Date(a.date || 0); // Nejnižší možný datum
  const dateB = new Date(b.date || 0);

  if (dateA.getTime() !== dateB.getTime()) {
    return dateA.getTime() - dateB.getTime(); // Vrátíme starší datum
  }

  // Pokud date jsou shodná, řadíme podle createdAt s předpokladem, že undefined je nejmladší
  const createdAtA = new Date(a?.createdAt || 0);
  const createdAtB = new Date(b?.createdAt || 0);

  return createdAtA.getTime() - createdAtB.getTime(); // Vrátíme starší datum
};

const relationCompareFnDesc = (
  a: AbstractAssetRelation,
  b: AbstractAssetRelation
) => {
  return relationCompareFn(a, b) * -1;
};

const assetListSelector = assetEntityAdapter.getSelectors(
  (state: RootState) => state.asset.list
);

const assetCompareFn = (a: AssetType, b: AssetType) => {
  return (
    -1 *
    (a.createdAt || "9999-12-31").localeCompare(b.createdAt || "9999-12-31")
  );
};

export const selectAssetIds = assetListSelector.selectIds;
export const selectAssetEntities = assetListSelector.selectEntities;
export const selectAssetsAll = assetListSelector.selectAll;
export const selectAssetById = assetListSelector.selectById;

export const selectListFetching = (state: RootState) =>
  state.asset.listFetching;
export const selectListFetchingStatus = (state: RootState) =>
  state.asset.listFetchingStatus;

export const selectSelectedAsset = (state: RootState) =>
  state.asset.selectedAsset;
export const selectHasSelectedAsset = (state: RootState) =>
  !!state.asset.selectedAsset;
export const selectSelectedAssetMode = (state: RootState) =>
  state.asset.selectedAssetMode;

export const selectSelectedAssetProperty = createSelector(
  [selectSelectedAsset, (state, args) => args.property],
  (asset, property) => {
    if (!asset) {
      return null;
    }

    const keys = property.split(".");
    let current = asset;

    // Iteruje přes klíče a postupně prochází objektem
    for (let key of keys) {
      // Pokud klíč neexistuje v aktuálním objektu/vnořeném objektu, vrátí null
      //@ts-ignore
      if (!current || !current[key]) {
        return null;
      }
      //@ts-ignore
      if (current[key] === undefined) {
        return null;
      }
      //@ts-ignore
      current = current[key];
    }

    return current;
  }
);

export const selectAssetFilter = (state: RootState): AssetFilterType =>
  state.asset.filter;

export const selectAssetFilterProperty = createSelector(
  [selectAssetFilter, (state, args) => args.property],
  (filter, property) => {
    if (!filter) {
      return null;
    }

    // @ts-ignore
    return filter[property];
  }
);

export const selectAssetSearch = (state: RootState): AssetFilterType =>
  state.asset.search;

export const selectAssetSearchProperty = createSelector(
  [selectAssetSearch, (state, args) => args.property],
  (search, property) => {
    if (!search) {
      return null;
    }

    // @ts-ignore
    return search[property];
  }
);

const getRelations = (rels: any, compareFn: any, onlyLast = false): any => {
  const filtered = rels.filter((r: any) => r.enabled).sort(compareFn);
  if (filtered.length === 0) {
    return [];
  }
  if (onlyLast) {
    return filtered[0];
  }

  return filtered;
};

const buildMeta = (a: AssetType) => {
  return {
    currentUnitId:
      getRelations(a.unitRelations, relationCompareFnDesc, true)?.unit?.id ||
      null,
    currentQrCode:
      getRelations(a.qrCodeRelations, relationCompareFnDesc, true)?.qrCode ||
      null,
    fulltext: [
      a.title,
      a.description,
      a.inventoryNumber,
      a.serialNumber,
      a.producerNumber,
      getRelations(a.qrCodeRelations, relationCompareFnDesc, false)
        .map((r: any) => r.qrCode.content)
        .join(","),
      getRelations(a.supplierRelations, relationCompareFnDesc, false)
        .map((r: any) => r.supplier.title)
        .join(","),
      getRelations(a.manufacturerRelations, relationCompareFnDesc, false)
        .map((r: any) => r.manufacturer.title)
        .join(","),
    ]
      .join("|")
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9]/g, ""),
  };
};

export const getAssetMeta = (a: AssetType) => {
  return buildMeta(a);
};

export const selectAssetsFilteredAndSorted = createSelector(
  [selectAssetsAll, selectAssetFilter, selectProfile],
  (assets, filter, profile) => {
    if (!profile) {
      return [];
    }

    return assets
      .filter((a) => {
        const meta = buildMeta(a);
        const lastAssetMovement = getLastAssetMovement(a);
        const unitRelations = getRelations(
          a.unitRelations,
          relationCompareFn,
          false
        );
        return (
          (!filter.extraWithoutUnit || a.unitRelations.length === 0) &&
          (!filter.extraWithoutQrCode || a.qrCodeRelations.length === 0) &&
          (filter.unitIds.length === 0 ||
            filter.unitIds.includes(meta.currentUnitId)) &&
          (filter.assetMovementReasonIds.length === 0 ||
            (!!lastAssetMovement &&
              lastAssetMovement.direction.id ===
                ASSET_MOVEMENT_DIRECTION_CODE__out &&
              filter.assetMovementReasonIds.includes(
                lastAssetMovement.reason.id
              ))) &&
          (filter.fulltext === "" ||
            meta.fulltext.includes(
              filter.fulltext
                .trim()
                .toLowerCase()
                .replace(/[^a-z0-9]/g, "")
            ))
        );
      })
      .sort(assetCompareFn);
  }
);

export const selectAssetsFilteredAndSortedCount = createSelector(
  [selectAssetsFilteredAndSorted],
  (assets) => {
    return assets.length;
  }
);

export const selectListConfig = (state: RootState) => {
  return state.asset.listConfig;
};

export const selectAssetIdsFilteredAndSorted = createSelector(
  [selectAssetsFilteredAndSorted, selectListConfig],
  (assets, listConfig) => {
    return assets
      .map((asset) => {
        return asset.id;
      })
      .slice(
        listConfig.page * listConfig.pageSize,
        (listConfig.page + 1) * listConfig.pageSize
      );
  }
);

export const selectAssetsByUnitId = createSelector(
  [selectAssetsAll, (_, args) => args.unitId],
  (assets, unitId) => {
    return assets
      .filter((a) => {
        const meta = buildMeta(a);
        return unitId === meta.currentUnitId;
      })
      .sort(assetCompareFn);
  }
);

const selectAsset = (state: RootState, args: any) => {
  return selectAssetById(state, args.assetId);
};

export const selectAssetRelations = createSelector(
  [selectAsset, (state, args) => args.relationName],
  (asset, relationName) => {
    if (!asset || !asset.hasOwnProperty(relationName)) {
      return [];
    }

    // @ts-ignore
    return asset[relationName]
      .filter((r: any) => r.enabled)
      .sort(relationCompareFn);
  }
);

export const selectAssetRelationLast = createSelector(
  [selectAssetRelations],
  (relations) => {
    if (relations.length <= 0) {
      return null;
    }

    return relations[relations.length - 1];
  }
);

export const selectAssetRelatedEntityIdOfAssetRelationLast = createSelector(
  [selectAssetRelationLast, (state, args) => args.entityName],
  (relation, entityName) => {
    if (!relation) {
      return null;
    }

    return relation[entityName].id;
  }
);

export const selectAssetLastAssetUnitRelationPresence = createSelector(
  [selectAssetRelationLast],
  (relation) => {
    if (
      !relation ||
      !relation.assetUnitRelationPresences ||
      relation.assetUnitRelationPresences.length === 0
    ) {
      return null;
    }

    return relation.assetUnitRelationPresences.reduce(
      (youngest: any, current: any) => {
        const currentCreatedAt = new Date(current.createdAt);
        return !youngest || currentCreatedAt > new Date(youngest.createdAt)
          ? current
          : youngest;
      },
      null
    );
  }
);

export const selectAssetMovements = createSelector([selectAsset], (asset) => {
  return [...(asset?.assetMovements || [])].sort((a, b) =>
    a.createdAt.localeCompare(b.createdAt)
  );
});
