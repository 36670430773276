import {
  Autocomplete,
  Chip,
  Typography,
  TextField,
  Collapse,
  Box,
  DialogContent,
  Dialog,
  Avatar,
  AvatarGroup,
  Button,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  selectSelectedIssueMode,
  selectSelectedIssueProperty,
} from "../../../selectors/issueSelectors";
import issueSlice from "../../../slices/issueSlice";
import { unitAssetsGetAll } from "../../../../../api/api";
import { AssetType } from "../../../../../types/asset.types";
import { getAssetMeta } from "../../../../asset/selectors/asset.selector";
import QrCodeScanner from "../../../../common/components/QrCodeScanner/QrCodeScanner";
import { QrCodeScanner as QrCodeScannerIcon } from "@mui/icons-material";
import { File } from "../../../../../types/file.types";
import { QrCodeType } from "../../../../../types/qrCode.types";
import { Nullable } from "../../../../../types";

interface AssetOption {
  id: string;
  title: string;
  qrCode: Nullable<QrCodeType>;
  files: File[];
}

const IssueDetailFieldAssetEditableQrCode = (props: any) => {
  const { handleSubmit } = props;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleScan = (text: string) => {
    handleSubmit(text);
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={() => {
          handleOpen();
        }}
        color={"primary"}
      >
        <QrCodeScannerIcon color={"primary"} />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ p: 0 }}>
          <QrCodeScanner onScan={handleScan} />
        </DialogContent>
      </Dialog>
    </>
  );
};

const IssueDetailFieldAssetEditable = (props: any) => {
  const dispatch = useAppDispatch();
  const unitId = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "unitId" })
  );
  const value = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "assetIdInput" })
  );

  const [resetInputField, setResetInputField] = useState(0);
  const [assets, setAssets] = useState<AssetType[]>([]);
  const [errorMessage, setErrorMessage] = useState("");

  const changeValue = (value: any) => {
    dispatch(
      issueSlice.actions.selectedIssuePropertySet({
        property: "assetIdInput",
        value: value,
      })
    );
  };

  const handleScan = (value: string) => {
    setErrorMessage("");
    const asset = assets.find((a) => {
      const aMeta = getAssetMeta(a);
      return (aMeta.currentQrCode?.content || "-1") === value;
    });
    if (!!asset) {
      changeValue(asset.id);
    } else {
      setErrorMessage("Zařízení dle QR kódu nebylo nalezeno.");
    }
  };

  const options: AssetOption[] = assets
    .map((asset) => {
      const assetMeta = getAssetMeta(asset);
      return {
        id: asset.id,
        title: asset.title,
        qrCode: assetMeta.currentQrCode,
        files: asset.files,
      } as AssetOption;
    })
    .filter((a) => !!a.qrCode)
    .sort((a, b) => {
      const aX = a.qrCode?.label || "999-999-999";
      const bX = b.qrCode?.label || "999-999-999";

      if (aX !== bX) {
        return aX.localeCompare(bX);
      }

      return (a.title || "").localeCompare(b.title || "");
    });

  useEffect(() => {
    setErrorMessage("");
    setResetInputField((prev) => {
      return prev + 1;
    });
    unitAssetsGetAll({ unitId: unitId }).then((response) => {
      if (response.status === 200) {
        // @ts-ignore
        setAssets(response.data.assets);
      }
    });
  }, [unitId]);

  return (
    <Collapse in={options.length > 0}>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ flexGrow: 1, mr: 1 }}>
            <Autocomplete
              onChange={(event, newValue) => {
                if (!!newValue) {
                  changeValue(newValue);
                } else {
                  changeValue(null);
                }
              }}
              value={value}
              options={options.map((o) => o.id)}
              renderOption={(props, option) => {
                const { key, ...rest } = props;
                const assetOption = options.find((o) => o.id === option) || {
                  files: [],
                  id: "",
                  qrCode: null,
                  title: "",
                };
                const files = assetOption.files.filter(
                  (file: File) => file.type === "asset_photo"
                );
                return (
                  <li key={assetOption.id} {...rest}>
                    {!!assetOption.qrCode && (
                      <Chip
                        label={assetOption.qrCode.label}
                        size={"small"}
                        sx={{ mr: 1 }}
                      />
                    )}
                    <AvatarGroup
                      spacing="small"
                      max={2}
                      variant="circular"
                      total={files.length}
                      sx={{ mr: 1 }}
                    >
                      {files.map((file: File) => {
                        return (
                          <Avatar
                            key={file.id}
                            src={
                              process.env.REACT_APP_ENDPOINT_FILES +
                              "/" +
                              file.path +
                              "/thumbnails/" +
                              file.filename
                            }
                          />
                        );
                      })}
                    </AvatarGroup>
                    <Typography variant={"subtitle1"}>
                      <strong>{assetOption.title}</strong>
                    </Typography>
                  </li>
                );
              }}
              getOptionLabel={(option) => {
                const assetOption = options.find((o) => o.id === option) || {
                  files: [],
                  id: "",
                  qrCode: null,
                  title: "",
                };
                return assetOption.title as string;
              }}
              renderInput={(params) => (
                <TextField {...params} label={"Zařízení"} />
              )}
              key={resetInputField}
            />
          </Box>
          <Box>
            <IssueDetailFieldAssetEditableQrCode handleSubmit={handleScan} />
          </Box>
        </Box>
        <Collapse in={errorMessage !== ""}>
          <Box sx={{ mt: 0.5 }}>
            <small>{errorMessage}</small>
          </Box>
        </Collapse>
      </Box>
    </Collapse>
  );
};

const IssueDetailFieldAsset = (props: any) => {
  const { handleSubmit } = props;

  const mode = useAppSelector(selectSelectedIssueMode);

  switch (mode) {
    case "create": {
      return <IssueDetailFieldAssetEditable handleSubmit={handleSubmit} />;
    }
    case "update": {
      return <IssueDetailFieldAssetEditable handleSubmit={handleSubmit} />;
    }
    default: {
      return <></>;
    }
  }
};

export default IssueDetailFieldAsset;
