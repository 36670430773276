import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  selectAssetRelationLast,
  selectSelectedAsset,
  selectSelectedAssetProperty,
} from "../../../selectors/asset.selector";
import {
  Button,
  Divider,
  Grid,
  Hidden,
  Paper,
  Typography,
} from "@mui/material";
import issueSlice from "../../../../issue/slices/issueSlice";
import { fieldDescription } from "../../../../issue/components/IssueList/fields/fieldDescription";
import { fieldService } from "../../../../issue/components/IssueList/fields/fieldService";
import { fieldComments } from "../../../../issue/components/IssueList/fields/fieldComments";
import { fieldProgress } from "../../../../issue/components/IssueList/fields/fieldProgress";
import { AssetIssueRelation } from "../../../../../types/asset.types";
import { Box } from "@mui/system";
import { grey } from "@mui/material/colors";
import assetSlice from "../../../slices/asset.slice";
import { selectIssueIdsByIssueIdsAndSorted } from "../../../../issue/selectors/issueSelectors";
import { selectProfileHasPermissionRight } from "../../../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__createIssue } from "../../../../../libraries/enums/permissionRights";
import { buildIssue } from "../../../../issue/builders/issue.builder";
import { AddCircle } from "@mui/icons-material";
import { IssueType } from "../../../../../types";

const ButtonCreateIssue = (props: any) => {
  const dispatch = useAppDispatch();

  const asset = useAppSelector(selectSelectedAsset);
  const rightCreateIssue = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createIssue,
    })
  );
  const lastAssetUnitRelation = useAppSelector((state) =>
    selectAssetRelationLast(state, {
      assetId: asset?.id || -1,
      relationName: "unitRelations",
    })
  );

  const handleCreateIssue = () => {
    const defaults = {
      assetIdInput: asset?.id || -1,
      unitId: lastAssetUnitRelation.unit.id,
    };
    const issue: IssueType = { ...buildIssue(), ...defaults };
    dispatch(issueSlice.actions.selectedIssueSet(issue));
    dispatch(issueSlice.actions.selectedIssueModeSet("create"));
  };

  if (!rightCreateIssue || !asset) {
    return <></>;
  }

  return (
    <Button
      onClick={() => {
        handleCreateIssue();
      }}
      color={"primary"}
      variant={"contained"}
      startIcon={<AddCircle />}
    >
      Vytvořit servisní požadavek
    </Button>
  );
};

const AssetDetailModeReadTabIssues = () => {
  const dispatch = useAppDispatch();
  // @ts-ignore
  const assetIssueRelations: AssetIssueRelation[] = useAppSelector((state) =>
    selectSelectedAssetProperty(state, { property: "assetIssueRelations" })
  );

  const rows = useAppSelector((state) =>
    selectIssueIdsByIssueIdsAndSorted(state, {
      issueIds: assetIssueRelations.map((r) => r.issue.id),
    })
  );

  const columnsSizes = [4, 2, 3, 3];

  const columns = [
    fieldDescription,
    fieldService,
    fieldComments,
    fieldProgress,
  ];

  useEffect(() => {
    if (assetIssueRelations.length > 0) {
      dispatch(
        issueSlice.actions.issueListFetchByIssueIds({
          issueIds: assetIssueRelations.map((r) => r.issue.id),
        })
      );
    }
  }, [assetIssueRelations]);

  if (assetIssueRelations.length === 0) {
    return (
      <Typography variant={"subtitle1"}>
        Na zařízení není evidován žádný servisní požadavek.
      </Typography>
    );
  }

  return (
    <>
      <Box>
        <Box sx={{ textAlign: "right", mb: 2 }}>
          <ButtonCreateIssue />
        </Box>
        <Paper
          sx={{ border: (theme) => `1px solid ${theme.palette.divider}` }}
          elevation={0}
        >
          <Hidden mdDown>
            <Box
              sx={{
                bgcolor: grey[100],
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              }}
            >
              <Grid container>
                {columns.map((column, index) => {
                  return (
                    <Grid key={column.code} item xs={columnsSizes[index]} p={2}>
                      <Typography variant="subtitle2">
                        {column.label}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Hidden>

          <Hidden mdUp>
            <Divider />
          </Hidden>

          <Box>
            {rows.map((id, index) => {
              return (
                <Grid
                  container
                  onClick={() => {
                    dispatch(issueSlice.actions.selectedIssueSetById(id));
                    dispatch(assetSlice.actions.selectedAssetSet(null));
                  }}
                  sx={{
                    outline: 0,
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: "rgb(225, 245, 254)",
                    },
                    background:
                      index % 2 === 1
                        ? "rgba(0, 0, 0, 0.02)"
                        : "rgba(0, 0, 0, 0)",
                    borderBottom: "1px solid #ececec",
                    "&:last-of-type": {
                      borderBottom: 0,
                    },
                  }}
                  tabIndex={-1}
                  key={id}
                >
                  {columns.map((column, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        alignItems="center"
                        md={columnsSizes[index]}
                        key={column.code}
                      >
                        {React.createElement(column.component, {
                          issueId: id,
                        })}
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default AssetDetailModeReadTabIssues;
